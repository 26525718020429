
import '../../styles/GuessResults.css';  // Assurez-vous de créer et styliser ce fichier CSS

import { useTranslation } from '../../LanguageContext';

export default function GuessResultsHeaderComp({ traitsLabels }) {
    const { translate } = useTranslation();
    return <div className="grid-row">
        {traitsLabels.map((trait, index) => (
            <div key={index}  className={`grid-header  grid-${traitsLabels.length}cols`} >
                {translate(trait)}
            </div>
        ))}
    </div>
}