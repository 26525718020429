import React from 'react';
import PropTypes from 'prop-types'; // Pour la validation des props

function CardLayout({ title, button, children, zoomClass }) {
  return (
    <div className={`card ${zoomClass}`}>
      <div className="card-header d-flex justify-content-between align-items-center bg-primary text-white">
        <h3 className="mb-0">{title}</h3> {button}
      </div>
      <div className="card-body">
        {children}
      </div>
    </div>
  );
}

CardLayout.propTypes = {
  title: PropTypes.string.isRequired,
  button: PropTypes.node,
  children: PropTypes.node.isRequired,
};

export default CardLayout;  