import React from 'react';

export default function FooterComp() {
  return (
    <footer className="footer text-white w-100">
      <div className="container text-center">
        © Pokémind - 2024
      </div>
    </footer>

  );
};