import React, { useEffect, useState } from 'react';
import '../../styles/GuessResults.css';

/**
 * Renders a row of guess results in the game.
 * @param {number} props.guessIndex - The index of the guess.
 * @param {string[]} props.traitsLabels - The labels of the traits.
 * @param {Object} props.guess - The guess object.
 * @param {Function} props.setEnableSearch - The function to enable/disable search.
 * @param {Function} props.onError - The function to handle errors.
 * @returns {JSX.Element} The rendered component.
 */
export default function GuessResultsRowComp({ guessIndex, traitsLabels, guess, setEnableSearch, onError }) {
    const [visibleTraits, setVisibleTraits] = useState(Array(traitsLabels.length).fill(false));

    const [error, setError] = useState(null);

    useEffect(() => {
        // Gestion des erreurs : Vérifier si guess et ses propriétés sont valides
        if (!guess || !Array.isArray(guess.pokemonTraits) || guess.pokemonTraits.length !== traitsLabels.length) {
            const errorMsg = "Erreur : Guess ou ses propriétés ne sont pas valides.";
            setError(errorMsg);
            onError && onError(error); // Remonte l'erreur au parent si la fonction onError est fournie
            return;
        }
        if (guessIndex === 0) {
            setEnableSearch(false);
            guess.pokemonTraits.forEach((_, index) => {
                setTimeout(() => {
                    if (index === guess.pokemonTraits.length - 1) {
                        setEnableSearch(true);
                    }
                    setVisibleTraits(prevVisibleTraits => {
                        const newVisibleTraits = [...prevVisibleTraits];
                        newVisibleTraits[index] = true;
                        return newVisibleTraits;
                    });
                }, (index) * 400);
            });
        } else {
            setVisibleTraits(Array(traitsLabels.length).fill(true));
        }
    }, [setEnableSearch, guessIndex, guess.pokemonTraits, traitsLabels.length, onError, error, guess]);

    return (
        <div key={guessIndex} className="grid-row">
            {guess.pokemonTraits.map((pokemonTraitValue, traitIndex) => {
                const isVisible = visibleTraits[traitIndex];
                const traitLabel = { index: traitIndex, name: traitsLabels[traitIndex] };
                const applyFadeIn = guessIndex === 0 && isVisible && traitLabel !== 'pokemon';

                if (isVisible) {
                    try {
                        return createOneGridTrait(traitLabel, pokemonTraitValue, guess.res[traitIndex], traitsLabels.length, applyFadeIn);
                    } catch (err) {
                        const errorMsg = `Erreur lors de la création du trait : ${err.message}`;
                        setError(errorMsg);
                        onError && onError(errorMsg); 
                        return null; 
                    }
                }
                return null;
            })}
        </div>
    );
}

/**
 * Creates a grid trait for a given trait label, pokemon trait value, guess result enum, and number of traits.
 * 
 * @param {object} traitLabel - The label of the trait.
 * @param {string} pokemonTraitValue - The value of the pokemon trait.
 * @param {string} guessResultEnum - The guess result enum.
 * @param {number} numberOfTraits - The number of traits in the grid.
 * @returns {JSX.Element} The grid trait component.
 */
function createOneGridTrait(traitLabel, pokemonTraitValue, guessResultEnum, numberOfTraits, isVisible) {
    let rowContent = null;
    switch (traitLabel.name) {
        case 'pokemon':
            rowContent = createPokemonGridTrait(traitLabel, pokemonTraitValue);
            break;
        case 'type1':
        case 'type2':
        case 'color':
        case 'evolutionMax':
        case 'height':
        case 'evolutionStage':
        case 'weight':
            rowContent = getTraitContent(traitLabel, pokemonTraitValue, guessResultEnum);
            break;
        case 'habitat':
            rowContent = createHabitatTrait(pokemonTraitValue);
            break;
        default:
            rowContent = getTraitContent(traitLabel, pokemonTraitValue, guessResultEnum);
    }

    return (
        <div
            key={traitLabel.index}
            className={`grid-item grid-${numberOfTraits}cols ${guessResultEnum}-result ${isVisible ? 'zoom-slide-in' : ''}`}
        >
            {rowContent}
        </div>
    );
}

function getTraitContent(traitLabel, pokemonTraitValue, guessResultEnum) {
    switch (traitLabel.name) {
        case 'height':
            return createTrait(pokemonTraitValue / 10 + ' m', guessResultEnum);
        case 'weight':
            return createTrait(pokemonTraitValue / 10 + ' kg', guessResultEnum);
        case 'habitat':
            return createHabitatTrait(pokemonTraitValue);
        default:
            return createTrait(pokemonTraitValue ? pokemonTraitValue.name || pokemonTraitValue : "", guessResultEnum);
    }
}

function createTrait(value, guessResultEnum) {
    return (
        <span>
            {value}
            {guessResultEnum === 'greater' && <span className="arrow">&#9650;</span>}
            {guessResultEnum === 'lower' && <span className="arrow">&#9660;</span>}
        </span>
    );
}

function createPokemonGridTrait(traitLabel, pokemonTraitValue) {
    const image = `/images/sprites/${pokemonTraitValue.pokedexId}.png`;
    return (
        <div className="img-container">
            <img src={image} alt={traitLabel.name} title={traitLabel.name} />
        </div>
    );
}

function createHabitatTrait(pokemonTraitValue) {
    const imageHabitat = `/images/habitats/${pokemonTraitValue.pokemonHabitatId}.png`;
    return (
        <div className="img-container">
            <img src={imageHabitat} alt={pokemonTraitValue.name} title={pokemonTraitValue.name} />
        </div>
    );
}
