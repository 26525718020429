const translations = {
    en: {
        pokemon : "Pokémon",
        type1: "Type 1",
        type2: "Type 2",
        color: "Color",
        habitat: "Habitat",
        height: "Height",
        weight: "Weight",
        evolutionStage: "Evolution Stage",
        evolutionMax: "Max Evolution",
        generation: "Generation"
    },
    fr: {
        pokemon : "Pokémon",
        type1: "Type 1",
        type2: "Type 2",
        color: "Couleur",
        habitat: "Habitat",
        height: "Hauteur",
        weight: "Poids",
        evolutionStage: "Stade d'évolution",
        evolutionMax: "Évolution max",
        generation: "Génération"
    },
};

export default translations;