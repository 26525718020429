import React from 'react';
import { Link, useLocation } from 'react-router-dom';

export default function MenuComp() {
  const location = useLocation();

  const menuItems = [
    { to: '/pokemind', icon: 'images/blendBoard.png', label: 'Pokémind' },
    { to: '/pokechen', icon: 'images/chen2.png', label: 'PokéChen' },
    { to: '/pokedetective', icon: 'images/icon_detective3.png', label: 'PokéDetective' },
    { to: '/pokeombre', icon: 'images/icon_shadow.png', label: 'PokéOmbre' }

  ];

  return (
    <nav className="navbar navbar-expand-lg navbar-dark w-100">
      <div className="container d-flex justify-content-between align-items-center">
        <Link className="navbar-brand mr-4 logo" to="/">
         <img src="images/pokemind_logo2.png" alt="Pokemin" className="menu-image" /> POKÉMIND</Link>

        <ul className="navbar-nav mx-auto">
          {menuItems.map((item, index) => (
            <li className="nav-item " key={index}>
              {location.pathname === item.to ? (
                <span className="nav-link menu-item">
                  <div className="menu-container">
                    <img src={item.icon} alt={item.label} className="menu-image menu-image-selected" />
                    <span className="menu-text menu-text-selected">{item.label}</span>
                  </div>
                </span>
              ) : (
                <Link className="nav-link menu-item" to={item.to}>
                 
                  <div className="menu-container">
                    <img src={item.icon} alt={item.label} className="menu-image " />
                    <span className="menu-text">{item.label}</span>
                  </div>
                </Link>
              )}
            </li>
          ))}
        </ul>


      </div>
    </nav>


  );
};