
import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MenuComponent from './components/MenuComp.js'
import PokemindHomeComp from './components/gameClassical/PokemindHomeComp.js'
import HomeComp from './components/HomeComp.js'
import FooterComp from './components/FooterComp.js'
import PokedexComp from './components/gamePokedex/PokedexComp.js'
import PokeDetectiveComp from './components/PokeDetectiveComp.js'
import PokeOmbreComp from './components/PokeOmbreComp.js'


function App() {

  return (
        <Router> 
            <MenuComponent />
            <div className="container my-4">
            <Routes>
              <Route path="/" element={<HomeComp />} />
              <Route path="/pokemind" element={<PokemindHomeComp />} />
              <Route path="/pokechen" element={<PokedexComp />} />
              <Route path="/pokedetective" element={<PokeDetectiveComp />} />
              <Route path="/pokeombre" element={<PokeOmbreComp />} />
            </Routes>
            </div>
          <FooterComp />
        </Router>
  );
}

export default App;
