/**
 * Searches for Pokémon from the API based on the provided query, selected generations, and excluded Pokémon.
 * 
 * @param {string} query - The search query for Pokémon names.
 * @param {number[]} selectedGens - An array of selected generation numbers.
 * @param {number[]} excludedPokemons - An array of excluded Pokémon pokedex IDs.
 * @returns {Promise<{pokedexId: number, label: string, image: string}[]>} - A list of Pokémon results.
 * @throws {Error} - If there is an error while fetching Pokémon data from the API.
 */

import configData from "../../config.json";
export async function searchPokemonFromApi(query, selectedGens, excludedPokemons) {
    const generations = selectedGens.join(',');
    const excludesPokemons = excludedPokemons.join(',');
    const response = await fetch(configData.API_DOMAIN+`game/search?name=${query}&lang=fr&generations=${generations}&max=10&excludes=${excludesPokemons}`);
    
    if (!response.ok) {
        throw new Error('Erreur lors de la récupération des Pokémon');
    }

    const data = await response.json();
    return data.map(pokemon => ({
        pokedexId: pokemon.pokedexId,
        label: pokemon.name.fr,
        image: `/images/sprites/${pokemon.pokedexId}.png`
    }));
}
