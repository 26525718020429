import React from 'react';

export default React.memo(function PokedexDescriptionRow({ description }) {
    return <div className="row">
        <div className="col">
            <div className="blackboard card-zoom">
                <p className="quote"> {description.description} </p>
                <p className="author">Pokémon {description.version}</p>
            </div>
        </div>
    </div>
}, areEqual);

function areEqual(prevProps, nextProps) {
    // Example of deep comparison, adjust according to your use case
    return prevProps.description === nextProps.description;
}   