import React, { useState, useEffect } from 'react';
import SearchPokeComp from '../SearchPokeComp';
import CongratComp from '../CongratComp';
import '../../styles/pokedex.css';
import { tryNewGuessInApi } from '../api/guessApi';
import PokedexDescriptionRow from './PokedexDescriptionRow';
import PokedexTableGuesses from './PokedexTableGuesses';
import { useMediaQuery } from 'react-responsive';

/**
 * PokedexComp component that represents the core of the Pokédex game.
 * 
 * @param {Object} props.gamePokedexInfos - The game Pokedex information.
 * @param {Function} props.updateGameInfos - The function to update game information.
 * @param {Function} props.onError - The function to handle errors.
 * @returns {JSX.Element} The PokedexComp component.
 */
export default function PokedexComp({ gamePokedexInfos, updateGameInfos, onError }) {
    const [enableSearch] = useState(true);
    const [guessePokemons, setGuessedPokemons] = useState([]);
    const [isGuessed, setIsGuessed] = useState(false);
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    // Update the guessed pokemons and isGuessed state when the gamePokedexInfos change
    useEffect(() => {
        setGuessedPokemons(gamePokedexInfos.guesses);
        if (gamePokedexInfos.isGuessed) {
            setIsGuessed(true);
        }
    }, [gamePokedexInfos]);

    // Helper function to render the guessing component
    const renderGuessingComponent = () => (
        <div className={isMobile ? 'search-pokedex-newline' : 'col col-6 justify-content-center align-self-center'}>
            {isGuessed && (enableSearch || gamePokedexInfos.isFinished) ? (
                <CongratComp gameInfos={gamePokedexInfos} updateGameInfos={updateGameInfos} />
            ) : (
                <SearchPokeComp
                    enableSearch={true}
                    excludedPokemons={guessePokemons.map(guess => guess.pokedexId)}
                    onPokemonSelect={tryGuessFromNewPokemon(gamePokedexInfos, updateGameInfos, setGuessedPokemons, setIsGuessed, onError)}
                    selectedGens={gamePokedexInfos.selectedGens}
                    onError={onError}
                />
            )}
        </div>
    );

    return (
        <div className='pokedex_container'>
            <PokedexDescriptionRow description={gamePokedexInfos.description} />
            <div className="row">
                {!isMobile && renderGuessingComponent()}
                <div className="col col-5 col-lg-4 justify-content-left align-self-left">
                    <img src="images/professor_oak2.png" alt="Professeur Chen" title="Professeur Chen" />
                </div>
               
            </div>
             {isMobile && renderGuessingComponent()}
            <PokedexTableGuesses guessePokemons={guessePokemons} isGuessed={isGuessed} />
        </div>
    );
}

/**
 * Function that handles the process of trying a new guess from a selected Pokemon in the game Pokedex.
 * 
 * @param {Object} gamePokedexInfos - The game Pokedex information.
 * @param {Function} updateGameInfos - The function to update the game information.
 * @param {Function} setGuesses - The function to set the guesses.
 * @param {Function} setIsGuessed - The function to set the isGuessed flag.
 * @returns {Function} - The async function that performs the try guess operation.
 */
function tryGuessFromNewPokemon(gamePokedexInfos, updateGameInfos, setGuesses, setIsGuessed, onError) {
    return async (selectedPokemon) => {
        try {
            if (!selectedPokemon) {
                return;
            }
            let gameInfosChange = { ...gamePokedexInfos, guessPokemonId: selectedPokemon.pokedexId };

            const data = await tryNewGuessInApi('pokedex', gameInfosChange);

            updateGameInfos({ ...data });

            // Save data in local storage
            localStorage.setItem('pokedexGameInfos', JSON.stringify(data));

            setGuesses(data.guesses);
            // Vérifier si le Pokémon est trouvé
            if (data.isGuessed) {
                setIsGuessed(true);
            }

        } catch (error) {
            onError(error);
        }

    };
}

