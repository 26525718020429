// Game.js
import React, { useState, useEffect } from 'react';
import SearchPokeComp from '../SearchPokeComp';
import GuessResultsTableComp from './GuessResultsTableComp';
import CongratComp from '../CongratComp';
import { tryNewGuessInApi } from '../api/guessApi';

import '../../styles/GameComp.css';


export default function PokemindHomeComp({ gameInfos, updateGameInfos, onError }) {
    const [guesses, setGuesses] = useState([]);
    const [isGuessed, setIsGuessed] = useState(false);
    const [traits, setTraits] = useState([]);
    const [excludedPokemons, setExcludedPokemons] = useState([]);
    const [enableSearch, setEnableSearch] = useState(true);

    useEffect(() => {
        setGuesses(Array.isArray(gameInfos.guesses) ? gameInfos.guesses : null);
        setTraits(Array.isArray(gameInfos.traits) ? gameInfos.traits : []);
        setExcludedPokemons(Array.isArray(gameInfos.guesses) ? gameInfos.guesses.map(guess => guess.pokemonId) : []);

        if (gameInfos.isGuessed) {
            setIsGuessed(true);
        }
    }, [gameInfos]);


    return (
        <div className="gameDiv1">
            {isGuessed && (enableSearch || gameInfos.isFinished) ? (
                <CongratComp gameInfos={gameInfos} updateGameInfos={updateGameInfos} />
            ) : (
                <SearchPokeComp
                    onPokemonSelect={handlePokemonSelector(gameInfos, updateGameInfos, setGuesses, setTraits, setExcludedPokemons, setIsGuessed , onError)}
                    selectedGens={gameInfos.selectedGens}
                    excludedPokemons={excludedPokemons}
                    enableSearch={enableSearch}
                    onError={onError}
                />
            )}
            <GuessResultsTableComp guesses={guesses} traitsLabels={traits} setEnableSearch={setEnableSearch} onError={onError} />
        </div>
    );
};


function handlePokemonSelector(gameInfos, updateGameInfos, setGuesses, setTraits, setExcludedPokemons, setIsGuessed, onError) {
    return async (selectedPokemon) => {
        if (!selectedPokemon) {
            return;
        }
        try {
            let gameInfosChange = { ...gameInfos, guessPokemonId: selectedPokemon.pokedexId };
            
            const data = await tryNewGuessInApi('pokemind', gameInfosChange);

            updateGameInfos({ ...data });

            // Save data in local storage
            localStorage.setItem('pokemindGameInfos', JSON.stringify(data));

            // Mettre à jour les guesses avec le nouveau guess
            setGuesses(data.guesses);
            setTraits(data.traits);
            setExcludedPokemons(data.guesses.map(guess => guess.pokemonId));

            // Vérifier si le Pokémon est trouvé
            if (data.isGuessed) {
                setIsGuessed(true);
            }

        } catch (error) {
            onError(error);
        }
    };
}
