import React, { useState, useEffect, useRef } from 'react';
import Confetti from 'react-confetti';
import '../styles/GameComp.css';

export default function CongratComp({ gameInfos, updateGameInfos }) {
    const containerRef = useRef(null);
    const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });
    useEffect(() => {
        const updateSize = () => {
            if (containerRef.current) {
                const newSize = {
                    width: containerRef.current.offsetWidth,
                    height: containerRef.current.offsetHeight,
                };
                
                setContainerSize(newSize);
            }
        };

        updateSize();
        window.addEventListener('resize', updateSize);

        return () => window.removeEventListener('resize', updateSize);
    }, [gameInfos]);

    return (<div ref={containerRef} className="alert alert-dismissible alert-success pokemon-sucess">
        {containerSize.width > 0 && <Confetti width={containerSize.width} height={containerSize.height} recycle={false} />}
        <strong>Félicitations ! Vous avez trouvé le pokémon {gameInfos.pokemonToFind.pokemon.name}</strong>  <br />
        <img src={`${gameInfos.pokemonToFind.pokemon.sprite}`} alt={gameInfos.pokemonToFind.pokemon.name} title={gameInfos.pokemonToFind.pokemon.name} className="sprite_pokemon_congrat" />
        <br /> <button className="btn btn-info" onClick={() => updateGameInfos(null)}> Nouvelle partie</button>
    </div>)
}