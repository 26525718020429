
import '../styles/Home.css';
import React from 'react';
import CardLayout from './CardLayout';
import HomeGameCard from './HomeGameCard';

export default function HomeComp() {
    return (
        <CardLayout title="Liste des jeux" zoomClass="card-zoom">
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
                <HomeGameCard
                    to="/pokemind"
                    imgSrc="images/blendBoard.png"
                    alt="Pokemind"
                    title="Pokémind"
                    description="Devine le Pokémon mystère en utilisant les indices basés sur tes propositions précédentes. Les indices te dévoileront des infos comme le type, la couleur, la génération et d'autres détails pour t'aider à le trouver!"
                />
                <HomeGameCard
                    to="/pokechen"
                    imgSrc="images/chen2.png"
                    alt="PokéChen"
                    title="PokéChen"
                    description="Trouve le Pokémon caché en te basant uniquement sur sa description dans le Pokédex. Des indices seront là pour t'aider si tu en as besoin !"
                />
                <HomeGameCard
                    to="/pokedetective"
                    imgSrc="images/icon_detective3_home.png"
                    alt="Pokedex"
                    title="PokéDétective"
                    description="À partir d'une image ultra-zoomée, devine à quel Pokémon elle correspond. Avec chaque essai, l'image se dézoome progressivement pour t'aider à identifier plus facilement le Pokémon."
                />
                <HomeGameCard
                    to="/pokeombre"
                    imgSrc="images/icon_shadow_home.png"
                    alt="Pokedex"
                    title="PokéOmbre"
                    description="Essaie de deviner quel Pokémon se cache derrière la silhouette mystérieuse. Si tu es bloqué, des indices te guideront."
                />
            </div>
        </CardLayout>
    );
}

