import React, { useEffect, useState } from 'react';
import '../../styles/GuessResults.css';  // Assurez-vous de créer et styliser ce fichier CSS
import GuessResultsHeaderComp from './GuessResultsHeaderComp';
import GuessResultsRowComp from './GuessResultsRowComp';

export default function GuessResultsTableComp({ guesses, traitsLabels, setEnableSearch, onError }) {
    // État pour gérer les erreurs
    const [error, setError] = useState(null);

    // Utilisation inconditionnelle du hook `useEffect`
    useEffect(() => {
        if (!guesses) {
            const errorMsg = "Erreur: 'guesses' doit être un tableau valide";
            console.error(errorMsg, guesses);
            setError(errorMsg);
            onError(errorMsg); // Appel de la fonction de gestion des erreurs passée en props
        }  else {
            // Réinitialiser l'erreur si tout est correct
            setError(null);
        }
    }, [guesses, onError]);

    // Si une erreur est présente, l'afficher
    if (error) {
        return <div>{error}</div>;
    }



    // Rendu principal si aucune erreur
    return (
        <div className="container mt-4">
            <GuessResultsHeaderComp traitsLabels={traitsLabels} />

            {guesses.slice().reverse().map((guess, guessIndex) => (
                <GuessResultsRowComp 
                    key={guesses.length - guessIndex} 
                    guessIndex={guessIndex} 
                    traitsLabels={traitsLabels} 
                    guess={guess} 
                    setEnableSearch={setEnableSearch} 
                    onError={onError}
                />
            ))}
        </div>
    );
}
