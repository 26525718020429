import React from 'react';
import { Link } from 'react-router-dom';


export default function HomeGameCard({ to, imgSrc, alt, title, description }) {

  return (
    <div className="col">
      <div className="card h-100 shadow-sm card-description">
        <Link to={to} className="text-decoration-none text-dark">
          <img src={imgSrc} className="card-img-top" alt={alt} title={title} />
          <div className="card-body text-center">
            <h4 className="card-title">{title}</h4>
            <p className="card-text">{description}</p>
          </div>
        </Link>
      </div>
    </div>
  );
};