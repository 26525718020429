

export default function RetryGameComp({ retryFunction}) {

    return (
        <div className="right">
        <button type="button" className="btn btn-secondary btn-sm bt-restart" onClick={retryFunction}>   Nouvelle Partie </button>
    </div>
    )

}

