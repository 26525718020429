import React, { useState, useEffect } from 'react';
import RuleSelectorComp from '../rules/RuleSelectorComp';
import PokemindGameCoreComp from './PokemindGameCore';
import { ErrorBoundary } from "react-error-boundary";
import { fallbackRender } from '../GameErrorComp';
import RetryGameComp from '../RetryGameComp';
import CardLayout from '../CardLayout';


export default function PokemindHomeComp() {
    const [gameInfos, setGameInfos] = useState(null);
    const [error, setError] = useState(null);

    const updateGameInfos = (newGameInfos) => {
        if (newGameInfos == null) {
            localStorage.removeItem('pokemindGameInfos');
        }
        setGameInfos(newGameInfos);
        setError(null)
    };

    const handleError = (error) => {
        setError(error);
    };

    useEffect(() => {
        loadGameSaveFromStorage(setGameInfos);
    }, []);


    return (

        <CardLayout title="PokéMind" button={gameInfos &&
            <RetryGameComp retryFunction={() => updateGameInfos(null)} />}>

                {error ? (
                    <div className="alert alert-danger" role="alert">
                        Une erreur inconnue est survenue. Veuillez recommencer une nouvelle partie ou réesayer plus tard.
                        <br /><br /> <button className="btn btn-info" onClick={() => updateGameInfos(null)}> Nouvelle partie</button>
                    </div>
                ) : <div>

                    <ErrorBoundary fallbackRender={fallbackRender} onReset={() => { updateGameInfos(null) }}>
                        <div>
                            {!gameInfos ? (
                                <RuleSelectorComp gameInfosUpdate={setGameInfos} gameName={'pokemind'} onError={handleError} />
                            ) : (
                                <PokemindGameCoreComp gameInfos={gameInfos} updateGameInfos={updateGameInfos} onError={handleError} />
                            )}
                        </div>
                    </ErrorBoundary>


                </div>}


        </CardLayout>
    );
};


/**
 * Loads the game save from the local storage and updates the game information.
 * 
 * @param {function} setGameInfos - The function to set the game information.
 */
function loadGameSaveFromStorage(setGameInfos) {
    try {
        const storedGameInfos = JSON.parse(localStorage.getItem('pokemindGameInfos'));
        if (storedGameInfos) {
            if (storedGameInfos.isGuessed) {
                storedGameInfos.isFinished = true;
            }
            setGameInfos(storedGameInfos);
        }
    }
    catch (error) {
        console.error('Error while parsing gameInfos from localStorage:', error);
        setGameInfos(null);
    }
}

