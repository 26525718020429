import React, { useState, useEffect } from 'react';
import '../../styles/RuleSelector.css';

const types = ['fire', 'grass', 'water'];
const generations = [1, 2, 3, 4, 5, 6, 7, 8, 9];

/**
 * Component for selecting generations.
 * 
 * @param {Function} props.onGenerationChange - The function to be called when generation changes.
 * @returns {JSX.Element} The GenerationSelectorComp component.
 */
export default function GenerationSelectorComp({ handleSetSelectedGenerations, selectedGenerations }) {
    const [selectedType, setSelectedType] = useState([]);

    useEffect(() => {
        const selectedTypes = generations.map(() => {
            const randomType = types[Math.floor(Math.random() * types.length)];
            return randomType;
        });

        setSelectedType(selectedTypes);
    }, []);

    if (!selectedType || selectedType.length === 0) {
        return <p>Chargement...</p>; // Afficher tant que selectedType n'est pas défini
    }

    return (
        <div className="generation-selector">
            {generations.map(gen => (
                <GenerationItem
                    key={gen}
                    gen={gen}
                    isSelected={selectedGenerations.includes(gen)}
                    selectedType={selectedType[gen - 1]}
                    onToggle={onSelectGeneration(selectedGenerations, handleSetSelectedGenerations)}
                />
            ))}
        </div>
    );
};

/**
 * Represents a Generation Item component.
 *
 * @param {number} props.gen - The generation number.
 * @param {boolean} props.isSelected - Indicates if the item is selected.
 * @param {string} props.selectedType - The selected type.
 * @param {Function} props.onToggle - The function to toggle the item.
 * @returns {JSX.Element} The Generation Item component.
 */
const GenerationItem = ({ gen, isSelected, selectedType, onToggle }) => (
    <div
        className={`generation-item ${isSelected ? 'generation-item-select' : ''}`}
        onClick={() => onToggle(gen)}
    >
        <img
            src={`/images/starter_gens/gen_${gen}_${selectedType}.png`}
            alt={`Gen ${gen} ${selectedType}`}
        />
         <div className={`generation-number ${isSelected ? 'generation-number-select' : ''}`}>{gen}</div>
    </div>
);


/**
 * Handles the selection of generations.
 *
 * @param {Array} selectedGenerations - The currently selected generations.
 * @param {Function} setSelectedGenerations - The function to set the selected generations.
 * @param {Function} onGenerationChange - The callback function to be called when the generation changes.
 * @returns {Function} - The selection handler function.
 */
function onSelectGeneration(selectedGenerations, handleSetSelectedGenerations) {
    return (id) => {

        if (selectedGenerations.length === 1 && selectedGenerations.includes(id)) {
    
            return;
        }

        const newSelections = selectedGenerations.includes(id)
            ? selectedGenerations.filter(genId => genId !== id)
            : [...selectedGenerations, id];

        saveSelection(handleSetSelectedGenerations, newSelections);
    };
}



/**
 * Saves the selection of generations.
 *
 * @param {function} setSelectedGenerations - The function to set the selected generations.
 * @param {Array} newSelections - The new selections to be saved.
 * @param {function} onGenerationChange - The function to be called when the generation changes.
 */
function saveSelection(handleSetSelectedGenerations, newSelections) {
    localStorage.setItem('selectedGenerations', JSON.stringify(newSelections));
    handleSetSelectedGenerations(newSelections);
}
