/**
 * Creates a new game in the API.
 * 
 * @param {Array} selectedGens - The selected generations for the game.
 * @param {string} gameName - The name of the game.
 * @returns {Promise} - A promise that resolves to the data of the created game.
 * @throws {Error} - If there is an error during the creation of the game.
 */

import configData from "../../config.json";
export async function createNewGameInApi(selectedGens, gameName) {

    const response = await fetch(configData.API_DOMAIN +gameName + '/new', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            "language": "fr",
            "generations": selectedGens
        })
    });

    if (!response.ok) {
        throw new Error('Erreur lors de la création de la partie');
    }

    const data = await response.json();
    return data;
}
