import React, { useState, useEffect } from 'react';
import GenerationSelectorComp from './GenerationSelectorComp';
import '../../styles/RuleSelector.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faPlay } from '@fortawesome/free-solid-svg-icons';
import { createNewGameInApi } from '../api/newGameApi';

/**
 * RuleSelectorComp component.
 * 
 * @param {Function} props.gameInfosUpdate - The function to update game information.
 * @param {string} props.gameName - The name id of the game.
 * @returns {JSX.Element} The RuleSelectorComp component.
 */
export default function RuleSelectorComp({ gameInfosUpdate, gameName, setError }) {
    const [rules, setRules] = useState({}); // Gérer les règles ici
    const [selectedGenerations, setSelectedGenerations] = useState([1]);

    const handleSetSelectedGenerations = (selectedGens) => {
        setSelectedGenerations(selectedGens);
        setRules({ ...rules, generations: selectedGenerations });
    }

    useEffect(() => {
        const storedGenerations = JSON.parse(localStorage.getItem('selectedGenerations'));
        if (storedGenerations) {
    
            if (storedGenerations.length > 0) {
                setSelectedGenerations(storedGenerations);
                setRules({ ...rules, generations: storedGenerations });
            }
        }
        else {
            setRules({ ...rules, generations: selectedGenerations  });
        }
    
        // eslint-disable-next-line
    }, []);

    return (
        <div>
            <div className="section-header">
                <div className="icons">
                    <FontAwesomeIcon icon={faStar} />
                    <FontAwesomeIcon icon={faStar} />
                    <FontAwesomeIcon icon={faStar} />
                </div>
                <h2>Générations </h2>

            </div>
            <GenerationSelectorComp handleSetSelectedGenerations={handleSetSelectedGenerations} selectedGenerations={selectedGenerations} />
            <br />
            <button className="btn-info2" onClick={startNewGame(rules, gameName, gameInfosUpdate, setError)}>  <FontAwesomeIcon icon={faPlay} /> Commencer une nouvelle partie</button>
        </div>
    );
}


/**
 * Starts a new game with the given rules and game information.
 * @param {object} rules - The rules for the game.
            * @param {string} gameName - The game identifier.
            * @param {function} gameInfosUpdate - The function to update the game information.
            * @returns {Promise < void>} - A promise that resolves when the new game is started.
            */
function startNewGame(rules, gameName, gameInfosUpdate, setError) {
    return async () => {
        try {
            const gameData = await createNewGameInApi(rules.generations, gameName);
            const keyStorage = gameName + 'GameInfos';
            localStorage.setItem(keyStorage, JSON.stringify(gameData));
            gameInfosUpdate(gameData);
            setError(null);
        }
        catch (error) {
            setError(error);
        }

    };
}

