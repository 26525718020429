function fallbackRender({ error, resetErrorBoundary }) {
    return (
        <div className="alert alert-danger" role="alert">
            <br/>
            Une erreur inconnue est survenue. Veuillez recommencer une nouvelle partie ou réesayer plus tard.
            <br /> <br/><button className="btn btn-info" onClick={resetErrorBoundary}> Nouvelle partie</button>
        </div>
    );
}

export { fallbackRender };