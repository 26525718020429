import React, { useState } from 'react';
import CardLayout from './CardLayout';
import { ErrorBoundary } from "react-error-boundary";
import { fallbackRender } from './GameErrorComp';
import RetryGameComp from './RetryGameComp';

export default function PokeOmbreComp() {
    const [gameOmbreInfos] = useState(null);
    const updateGameInfos = (newGameInfos) => {

    };

    return (

        <CardLayout title="PokéOmbre" button={gameOmbreInfos &&
            <RetryGameComp retryFunction={() => updateGameInfos(null)} />}>

            <ErrorBoundary fallbackRender={fallbackRender} onReset={() => { updateGameInfos(null) }} >
            <div>
                        <h1>Coming soon...</h1>
                    </div>
            </ErrorBoundary>


        </CardLayout >
    );
};
