
/**
 * Creates a new game in the API.
 * 
 * @param {Array} selectedGens - The selected generations for the game.
 * @param {string} gameName - The name of the game.
 * @returns {Promise} - A promise that resolves to the data of the created game.
 * @throws {Error} - If there is an error during the creation of the game.
 */

import configData from "../../config.json";

export async function tryNewGuessInApi(gameName, newGameInfos) {

    const body_request = {
        gameId: newGameInfos.gameId,
        guessPokemonId: newGameInfos.guessPokemonId
    }

    const response = await fetch(configData.API_DOMAIN + gameName + '/guess', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body_request),
    });


    if (!response.ok) {
        throw new Error('Erreur lors du nouveau guess');
    }

    const data = await response.json();
    return data;
}
