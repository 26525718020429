import React, { useState, useEffect } from 'react';
export default function PokedexTableGuesses({ guessePokemons, isGuessed }) {
    const [fadeIn, setFadeIn] = useState(false); // État pour gérer l'animation fade-in

    useEffect(() => {
        setFadeIn(true); 

        const timeout = setTimeout(() => setFadeIn(false), 1200); 
        return () => clearTimeout(timeout);
    }, [guessePokemons]);

    return <div className="image-container d-flex flex-wrap">

        {guessePokemons.slice().reverse().map((guessPokemon, index) => {
            const image = `/images/sprites/${guessPokemon.pokedexId}.png`;
            const classImageId = index === 0 && fadeIn ? 'fade-in' : ''; // Ajouter la classe fade-in
            const classGuessImage = isGuessed && index === 0 ? 'guessed-image' : '';
            return (
                <div className="img-container img-pokedex-list" key={guessPokemon.pokedexId} >
                    <img src={image} alt={guessPokemon.name} title={guessPokemon.name} className={`img-fluid ${classImageId} ${classGuessImage}`} />
                </div>
            );
        })}
    </div>
}