import React, { useState, useEffect } from 'react';
import PokedexCoreComp from './PokedexCoreComp';
import RuleSelectorComp from '../rules/RuleSelectorComp';
import { ErrorBoundary } from "react-error-boundary";
import { fallbackRender } from '../GameErrorComp';
import RetryGameComp from '../RetryGameComp';
import CardLayout from '../CardLayout';


/**
 * PokedexComp component for the Pokédex game.
 *
 * @returns {JSX.Element} The rendered PokedexComp component.
 */
export default function PokedexComp() {
    const [gamePokedexInfos, setGamePokedexInfos] = useState(null);
    const [error, setError] = useState(null);

    // Load the game information from local storage when the component is mounted
    useEffect(() => {
        loadGameInfosFromLocalStorage(setGamePokedexInfos);
    }, []);

    // Update the game information
    // @param {object} newGameInfos - The new game information
    const updateGameInfos = (newGameInfos) => {
        setGamePokedexInfos(newGameInfos);
        if (newGameInfos == null) {
            localStorage.removeItem('pokedexGameInfos');
        }
    };

    // Handle the error
    const handleError = (error) => {
        setError(error);
    };
    
    return (
        <CardLayout title="PokéChen" button={gamePokedexInfos &&
            <RetryGameComp retryFunction={() => updateGameInfos(null)} />}>

            <ErrorBoundary fallbackRender={fallbackRender} onReset={() => { updateGameInfos(null) }} >
                <div>
                    {!gamePokedexInfos || error ? (
                        <RuleSelectorComp gameInfosUpdate={setGamePokedexInfos} gameName={'pokedex'} setError={setError} />
                    ) : (
                        <PokedexCoreComp gamePokedexInfos={gamePokedexInfos} updateGameInfos={updateGameInfos} onError={handleError} />
                    )}
                </div>
            </ErrorBoundary>


        </CardLayout >
    );
};


/**
 * Loads game information from local storage and updates the game Pokedex information.
 * 
 * @param {function} setGamePokedexInfos - The function to set the game Pokedex information.
 */
function loadGameInfosFromLocalStorage(setGamePokedexInfos) {
    const storedGameInfos = JSON.parse(localStorage.getItem('pokedexGameInfos'));
    if (storedGameInfos) {

        if (storedGameInfos.isGuessed) {
            storedGameInfos.isFinished = true;
        }
        setGamePokedexInfos(storedGameInfos);
    }
}

