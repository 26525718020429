import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import Select from 'react-select';
import '../styles/SearchPoke.css';
import { searchPokemonFromApi } from './api/pokemonApi';
export default function SearchPokeComp({ onPokemonSelect, selectedGens, excludedPokemons, enableSearch, onError }) {
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [selectedValue, setSelectedValue] = useState(null);

    // Référence pour le composant Select
    const selectRef = useRef(null);

    // Fonction pour effectuer une recherche de Pokémon avec useMemo pour capturer les dépendances
    const fetchPokemon = useMemo(() => {
        return async (query) => {
            setIsSearching(true);
            try {
                const formattedResults = await searchPokemonFromApi(query, selectedGens, excludedPokemons);
                setResults(formattedResults);
            } catch (error) {
                console.error('Erreur lors de la recherche des Pokémon:', error);
                onError('Erreur lors de la recherche des Pokémon');
            } finally {
                setIsSearching(false);
            }
        };
    }, [excludedPokemons, selectedGens, onError]);

    // Version débouncée de fetchPokemon
    const debouncedFetchPokemon = useMemo(() => debounce(fetchPokemon, 300), [fetchPokemon]);

    // Gère les changements d'entrée de l'utilisateur
    const handleChange = useCallback((inputValue) => {
        setQuery(inputValue);
        // Lancer la recherche seulement si la requête a au moins 2 caractères
        if (inputValue.length >= 2) {
            debouncedFetchPokemon(inputValue);
        } else {
            setResults([]); // Réinitialiser les résultats si la requête est trop courte
        }
    }, [debouncedFetchPokemon]);

    useEffect(() => {
        // Focus sur le composant Select lorsque enableSearch passe à true
        if (enableSearch && selectRef.current) {
            selectRef.current.focus();
        }
    }, [enableSearch]);

    const customStyles = {
        option: (provided) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
            color: 'black',
            padding: 10,
            zIndex: 5000
        }),
        singleValue: (provided) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
        }),
        placeholder: (provided) => ({
            ...provided,
            textAlign: 'left'
        })
    };

    const formatOptionLabel = ({ label, image }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={image} alt={label} style={{ width: 50, height: 50, marginRight: 10 }} />
            {label}
        </div>
    );

    return (
        <div>
            <Select
                className='searchPokeBar'
                ref={selectRef}
                isDisabled={!enableSearch}
                id="exampleSelect2"
                options={results}
                styles={customStyles}
                value={selectedValue}
                isClearable
                formatOptionLabel={formatOptionLabel}
                noOptionsMessage={() => {
                    if (query.length >= 2) {
                        return 'Aucun pokémon trouvé';
                    }
                    else {
                        return 'Entrez au moins 2 caractères';
                    }
                }}
                onInputChange={handleChange}
                onChange={(selectedOption) => {
                    if (!selectedOption) {
                        return;
                    }
                    const selectedPokemon = results.find(pokemon => pokemon.pokedexId === selectedOption.pokedexId);
                    onPokemonSelect(selectedPokemon);
                    setSelectedValue(null);
                }}
                placeholder="Proposer un Pokémon..."
                isLoading={isSearching}
                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
            />
        </div>
    );
}

// Fonction utilitaire pour le debounce
function debounce(func, delay) {
    let debounceTimer;
    return function (...args) {
        const context = this;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
}
